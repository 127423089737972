import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Observable, of} from 'rxjs';
import {environment} from '../../../../../environments/environment';
import {tap} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private loggedUser: string | undefined;
  private readonly ACCESS_TOKEN: string = 'ACCESS_TOKEN';
  private readonly currentUser: string = 'current_user';
  private readonly REFRESH_TOKEN: string = 'REFRESH_TOKEN';

  options = {
    headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
  };

  constructor(private http: HttpClient) { }

  public login(body: { email: string, password: string }): Observable<any> {
    return this.http.post(`${environment.baseUrl}/auth/login`, body)
      .pipe(
        tap(res => this.setCurrentUser(res)),
        tap(res => this.setSession(res))
      );
  }

  private setCurrentUser(user: any): void {
    localStorage.setItem(this.currentUser, JSON.stringify(user));
  }

  private setSession(token: any): void {
    this.loggedUser = token.email;
    this.storeTokens(token);
    this.storeRefreshToken(token);
  }

  public storeTokens(tokens: any): void {
    localStorage.setItem(this.ACCESS_TOKEN, tokens.accessToken);
  }

  private storeRefreshToken(token: any): void {
    localStorage.setItem(this.REFRESH_TOKEN, token.refreshToken);
  }

  get getCurrentUser(): any {
    return JSON.parse(localStorage.getItem(this.currentUser) as string);
  }

  isSignedIn(): any {
    return !!this.getAccessToken();
  }

  public getAccessToken(): any {
    return localStorage.getItem(this.ACCESS_TOKEN);
  }

  public getRefreshToken(): any {
    return localStorage.getItem(this.REFRESH_TOKEN);
  }

  public refreshToken(): Observable<any> {
    const body = new HttpParams()
      .set('refreshToken', this.getRefreshToken() as string);
    return this.http.post(`${environment.baseUrl}/auth/refresh-token`, body.toString(), this.options);
  }

  public logout(): Observable<any> {
    return of({})
      .pipe(tap(_ => localStorage.clear()));
  }
}
