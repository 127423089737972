import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormFieldsModule} from './form-fields/form-fields.module';
import {NgxUiLoaderConfig, NgxUiLoaderModule, PB_DIRECTION, POSITION, SPINNER} from 'ngx-ui-loader';
import {NgxPaginationModule} from 'ngx-pagination';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatDialogModule} from '@angular/material/dialog';
import {MatSnackBarModule} from '@angular/material/snack-bar';

const ngxUiLoaderConfig: NgxUiLoaderConfig = {
  bgsColor: '',
  bgsPosition: POSITION.centerCenter,
  bgsSize: 80,
  bgsType: SPINNER.circle, // background spinner type
  fgsType: SPINNER.threeStrings, // foreground spinner type
  pbDirection: PB_DIRECTION.leftToRight, // progress bar direction
  pbThickness: 5, // progress bar thickness,
  logoSize: 40,
  logoUrl: 'assets/images/loader.gif',
};

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    FormFieldsModule,
    NgxUiLoaderModule.forRoot(ngxUiLoaderConfig),
    NgxPaginationModule,
    MatTooltipModule,
    MatDialogModule,
    MatSnackBarModule
  ],
  exports: [
    FormFieldsModule,
    NgxUiLoaderModule,
    MatSnackBarModule
  ]
})

export class SharedModule {}
