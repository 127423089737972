import { Injectable } from '@angular/core';
import { HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import {catchError, filter, switchMap, take} from 'rxjs/operators';
import {BehaviorSubject, Observable, of, throwError} from 'rxjs';
import {Router} from '@angular/router';
import {AuthService} from '../../features/auth/pages/services/auth.service';
import {SnackbarService} from '../services/snackbar.service';

@Injectable()

export class ErrorInterceptor implements HttpInterceptor {

  private isRefreshing = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor(private authService: AuthService,
              private snackbarService: SnackbarService,
              private router: Router) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<any> {
    return next.handle(req)
      .pipe(
        catchError(err => {
          if (err?.status) {
            this.snackbarService.showMessage(err.error.message, '');
            if (err.status === 403) {
              return this.handler401Error(req, next);
            }
            if (err.status === 401) {
              this.authService.logout().subscribe(_ => this.router.navigate(['/auth/login']));
            }
            return throwError(err);
          }
          return throwError(err);
        })
      );
  }

  private handler401Error(request: HttpRequest<any>, next: HttpHandler): any {
    if (!this.isRefreshing) {

      this.isRefreshing = true;
      this.refreshTokenSubject.next(null);

      return this.authService.refreshToken()
        .pipe(
          switchMap(token => {
            this.isRefreshing = false;
            this.authService.storeTokens(token);
            this.refreshTokenSubject.next(token.accessToken);

            request = request.clone({
              setHeaders: {
                Authorization: `Bearer ${this.authService.getAccessToken()}`,
              }
            });
            return next.handle(request);
          }));
    } else {
      return this.refreshTokenSubject
        .pipe(
          filter(token => token !== null),
          take(1),
          switchMap(token => {
            request = request.clone({
              setHeaders: {
                Authorization: `Bearer ${token}`,
              }
            });
            return next.handle(request);
          })
        );
    }
  }
}
