import {NgModule} from '@angular/core';
import {ButtonComponent} from './components/button/button.component';
import { CheckboxComponent } from './components/checkbox/checkbox.component';
import { RadioComponent } from './components/radio/radio.component';
import {MatRadioModule} from '@angular/material/radio';

@NgModule({
  declarations: [ButtonComponent, CheckboxComponent, RadioComponent],
  imports: [MatRadioModule],
  exports: [ButtonComponent, CheckboxComponent, RadioComponent]
})

export class FormFieldsModule {}
